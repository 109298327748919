<template>
  <div class="rm-content">
    <div class="primary-section-content">
      <div v-if="isPropertyLevel">
        <HbPropertySelector
          v-if="isPropertyLevel && hasProperties"
          v-model="propertyId"
          :items="properties"
          item-value="id"
          item-text="name"
          :loading="propertyLoading"
          :disabled="propertyLoading"
        />
      </div>

      <div class="my-3 mr-6" v-else>
        <hb-notification
          v-model="warning"
          v-if="warning"
          type="caution"
          :have-title="false"
          @close="warning = false"
        >
          The Corporate Settings will apply as a default to all properties but
          can be overridden in the Property Settings.
        </hb-notification>
      </div>
      <div v-if="isPropertyLevel && !propertyId" class="mr-6">
        <hb-empty-state
          message="Please select a Property to continue."
        ></hb-empty-state>
      </div>

      <div v-else class="mr-6">
        <v-expansion-panels
          elevation="0"
          class="hb-expansion-panel"
          v-model="panel"
          :key="level"
          multiple
          :disabled="isPropertyLevel && !propertyId"
        >
          <hb-expansion-panel class="" :disabled="propertyLoading">
            <template v-slot:title> Rent Management </template>
            <template v-slot:actions>
              <span v-if="isPropertyLevel"> {{ getSwitchLabel }} </span>
              <hb-switch
                v-if="isPropertyLevel"
                v-model="isRentEnabled"
                :right="true"
                @click.stop.prevent="hasRevenuePermission('rent')"
                :readonly="viewOnlyMode.rent && readonlyRent"
              />
            </template>
            <template v-slot:content>
              <RentSettings
                ref="rentSettings"
                :documents="documents"
                :is-property-level="isPropertyLevel"
                :level="currentLevel"
                :property="propertyId"
                :view-only="viewOnlyMode.rent"
                @setSnackBar="setSnackNotification($event)"
                @setEnableStatus="setEnableStatus($event)"
                @setLoading="setLoading('rent', $event)"
              />
            </template>
          </hb-expansion-panel>
          <hb-expansion-panel
            class=""
            key="transactional"
            :disabled="propertyLoading"
          >
            <template v-slot:title> Rate Management </template>
            <template v-slot:actions>
              <span v-if="isPropertyLevel"> {{ getSwitchLabel }} </span>
              <hb-switch
                v-if="isPropertyLevel"
                v-model="isRateEnabled"
                :right="true"
                @click.stop.prevent="hasRevenuePermission('rate')"
              />
            </template>
            <template v-slot:content>
              <RateSettings
                :is-property-level="isPropertyLevel"
                ref="rateSettings"
                :level="currentLevel"
                :property="propertyId"
                @setSnackBar="setSnackNotification($event)"
                @setEnableStatus="setEnableStatus($event)"
                @setLoading="setLoading('rate', $event)"
              />
            </template>
          </hb-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>

    <hb-global-notification
      v-model="snackbar"
      :type="type"
      :description="description"
      :list="errorsList"
    ></hb-global-notification>
    <hb-modal
      v-model="showNoPermissionModel"
      size="medium"
      :title="permissionModalConf.title"
      :footerOff="true"
      confirmation
      show-help-link
      @close="showNoPermissionModel = false"
    >
      <template v-slot:content>
        <div class="px-6 py-4">
          <div class="pb-2">
            {{ permissionModalConf.description }}
          </div>
        </div>
      </template>
    </hb-modal>

    <!-- Confirmation pop up for disable rent changes -->
    <hb-modal
      v-model="disableRentManagement"
      size="medium"
      title="Disable Rent Management"
      confirmation
      show-help-link
      @close="confirmDisableRent"
    >
      <template v-slot:content>
        <div class="px-6 py-4">
          <div class="pb-2">
            <span
              >Disabling rent management will cancel all scheduled and approved
              rent changes.
              <div class="mt-3">Are you sure you want to proceed?</div>
            </span>
          </div>
        </div>
      </template>
      <template v-slot:right-actions>
        <hb-btn @click="confirmDisableRent(true)">Confirm</hb-btn>
      </template>
    </hb-modal>
  </div>
</template>

<script type="text/babel">
import { mapGetters } from "vuex";

import api from "../../../assets/api.js";

import RateSettings from "./RateSettings";
import RentSettings from "./RentSettings";
import { capitalizeFirstLetter } from "../../../utils/common";

export default {
  name: "revenue-management-settings",
  props: {
    level: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      warning: true,
      snackbar: false,
      type: "",
      description: "",
      errorsList: [],
      propertyId: null,
      hasProperties: false,
      propertyLoading: false,
      rateEnabled: false,
      rentEnabled: false,
      documents: [],
      openPanel: [0, 1],
      revenueArray: [],
      permissionModalConf: {
        title: "",
        description: "",
      },
      showNoPermissionModel: false,
      disableRentManagement: false,
      readonlyRent: false,
    };
  },
  components: {
    RateSettings,
    RentSettings,
  },
  computed: {
    ...mapGetters({
      properties: "propertiesStore/filtered",
      hasRolePermission: "authenticationStore/rolePermission",
    }),

    viewOnlyMode() {
      return {
        rent: !this.hasRolePermission("manage_rent_management"),
        rate: !this.hasRolePermission("manage_rate_management"),
      };
    },
    /**
     * @returns {String} - current level in lowercase
     */
    currentLevel() {
      return this.level.toLowerCase();
    },

    /**
     * @returns {Boolean} - true if current level is property, else false
     */
    isPropertyLevel() {
      return this.level === "Property";
    },

    /**
     * To return an array of accordion numbers which needs to be open by default
     *  - For Corporate level the accordion should be opened
     *  - For Property level the accordion will be opened only if property is selected
     *
     * @returns {Array} - An array of accordion numbers
     */
    panel() {
      return this.isPropertyLevel && !this.propertyId
        ? []
        : !this.isPropertyLevel
        ? [0, 1]
        : this.openPanel;
    },

    /**
     * to return expansion panel switch text
     * @returns {String}
     */
    getSwitchLabel() {
      return `Enable for this ${
        this.isPropertyLevel ? "Property" : "Corporate"
      }`;
    },

    isRentEnabled: {
      get() {
        return !!this.rentEnabled;
      },
      set(value) {
        this.rentEnabled = !!value;
        if (value) {
          this.openPanel.splice(0, 0, 0);
          this.$refs.rentSettings.setRentActive(value);
        }
      },
    },

    isRateEnabled: {
      get() {
        return !!this.rateEnabled;
      },
      set(value) {
        this.rateEnabled = !!value;
        if (value) {
          this.openPanel.splice(1, 0, 1);
          this.$refs.rateSettings.setRateActive(value);
        } else this.$refs.rateSettings.enableRateManagement(value);
      },
    },
  },

  watch: {
    isPropertyLevel: {
      async handler(val) {
        if (val) {
          if (this.properties.length > 1) {
            this.propertyId = "";
            this.hasProperties = true;
          } else if (this.properties.length === 1) {
            this.propertyId = this.properties[0].id;
            this.hasProperties = false;
          } else this.propertyId = "";
          if (this.propertyId) await this.getDocumentList();
        }
      },
      immediate: true,
    },
    propertyId: {
      async handler(val) {
        if (this.isPropertyLevel && val) {
          this.propertyLoading = true;
          this.revenueArray = [];
          await this.getDocumentList();
        }
      },
      immediate: true,
    },
    rentEnabled: {
      handler(val) {
        if (val) {
          this.readonlyRent = true;
        }
      },
      immediate: true,
    },
  },

  methods: {
    hasRevenuePermission(type) {
      if (this.viewOnlyMode[type]) {
        this.showNoPermissionModel = true;
        this.permissionModalConf = {
          title: `Unable to Edit ${capitalizeFirstLetter(
            type
          )} Management Settings`,
          description: `The signed in account does not have permission to edit ${type} management settings. To edit ${type} management settings, please contact your administrator.`,
        };
      } else {
        if (!this.rentEnabled && type === "rent")
          this.disableRentManagement = true;
      }
    },

    confirmDisableRent(btnClick = false) {
      this.rentEnabled = true;
      this.disableRentManagement = false;
      this.readonlyRent = false;
      if (btnClick) {
        this.rentEnabled = false;
        this.$refs.rentSettings.enableRentManagement(this.rentEnabled);
      }
    },

    setSnackNotification(data) {
      this.setSnackbar(data.type, data.message, data.errors);
    },

    /**
     * Triggers snackbar components with the given data
     *
     * @param {String} snackbarType - can be 'success' or 'error'
     * @param {String} snackbarText - text to be displayed in snackbar
     * @param {Array} errorList - list of errors if any.
     */
    setSnackbar(snackbarType, snackbarText, errorList = []) {
      this.snackbar = true;
      this.type = snackbarType;
      this.description = snackbarText;
      this.errorsList = errorList;
      setTimeout(() => {
        this.snackbar = false;
      }, 6000);
    },
    setEnableStatus(statusData) {
      if (!this.isPropertyLevel) {
        this.openPanel = [0, 1];
        return;
      }

      const typeMap = {
        rent: {
          enabledProp: "rentEnabled",
          index: 0,
        },
        rate: {
          enabledProp: "rateEnabled",
          index: 1,
        },
      };

      const { type, status } = statusData;
      const { enabledProp, index } = typeMap[type];

      this[enabledProp] = !!status;

      if (status) {
        if (!this.openPanel.includes(index))
          this.openPanel.splice(index, 0, index);
      } else {
        this.openPanel.splice(this.openPanel.indexOf(index), 1);
      }
    },

    async getDocumentList() {
      let path = this.isPropertyLevel
        ? `&property_ids[]=${this.propertyId}`
        : "";
      try {
        let response = await api.get(
          this,
          api.DOCUMENT + "?type[]=rent-change&limit=200&offset=1" + path
        );
        this.documents = response?.documents;
      } catch (err) {
        this.documents = [];
        console.error("Document fetch error", err);
      }
    },

    setLoading(type) {
      if (!this.revenueArray.includes(type)) this.revenueArray.push(type);
      if (this.revenueArray.length > 1) this.propertyLoading = false;
    },
  },
};
</script>

<style lang="scss">
.notificationMsg {
  textarea {
    line-height: 35px;
  }
}

.merge-field-text-wrap {
  a {
    i {
      margin-bottom: 2px;
    }
  }
}
</style>
