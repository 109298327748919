<template>
  <div>
    <div v-if="!loading">
      <template>
        <!-- <hb-form label="Enable Rent Management">
          <hb-switch
            v-model="isEnabled"
            class="mt-2"
            label="Enable Rent Management for this Property"
          />
        </hb-form> -->
        <hb-form v-if="isPropertyLevel" label="Engine">
          {{ rentEngine }}
        </hb-form>
        <hb-form v-if="showAutomationSection" label="Automation">
          <hb-switch
            v-model="enableAutomaticRent"
            label="Enable Automated Rent Changes"
            :readonly="viewOnly"
          />
        </hb-form>
        <hb-form
          label="View Rent Change Ahead By"
          description="Determine how far in advance rent changes get generated for the queue."
          v-if="showAutomationSection && enableAutomaticRent"
          required
        >
          <div class="d-flex mt-n2">
            <hb-autocomplete
              v-model="form.advance_rent_change_queue_months"
              name="rent_change_days"
              hide-details
              :items="generateNumbers(12)"
              :menu-props="{ maxHeight: 4 }"
              clearable
              data-vv-as="View Rent Change Ahead By"
              data-vv-name="rent_change_days"
              v-validate="`required|between:0,13|decimal:0`"
              placeholder="0"
              :error-messages="errors.collect('rent_change_days')"
              class="pa-0 ma-0 mt-3"
              style="max-width: 63px"
              box
              medium
              attach
              condensed
              :readonly="viewOnly"
            />
            <span class="d-flex align-self-center ml-2 mt-2"> Months</span>
          </div>
        </hb-form>
        <hb-form v-if="isPropertyLevel" label="Approval Type" multiple>
          <hb-radio-group
            v-model="form.approval_type"
            column
            hide-details
            v-validate="`required`"
            data-vv-name="approval_type"
            data-vv-as="Approval Type"
            :error-messages="errors.collect('approval_type')"
            :readonly="viewOnly"
          >
            <hb-radio
              label="Automate Rent Changes without Approval"
              value="automated"
            />
            <hb-radio
              label="Require Approval for Rent Changes"
              value="manual"
            />
          </hb-radio-group>
        </hb-form>

        <!-- <hb-form v-if="isPropertyLevel" label="Space Grouping Profile" required>
          <hb-select
            v-model="form.default_space_group_profile_id"
            name="space_group"
            attach
            hide-details
            v-validate="`required`"
            data-vv-name="space_group"
            data-vv-as="Space Grouping Profile"
            :error-messages="errors.collect('space_group')"
            placeholder="Select Space Grouping Profile"
            :items="spaceGroups"
            item-text="name"
            item-value="id"
            :clearable="false"
            class="pa-0 ma-0 mt-3"
            :readonly="viewOnly"
          />
        </hb-form> -->
        <hb-form
          v-if="isPropertyLevel"
          label="Minimum Rent Change Intervals"
          description="Minimum time period required between rent changes"
          required
        >
          <div class="d-flex mt-n2">
            <hb-autocomplete
              v-model="form.min_rent_change_interval"
              name="rent_change_interval"
              hide-details
              :items="generateNumbers(12)"
              clearable
              placeholder="0"
              data-vv-as="Rent Change Interval"
              data-vv-name="rent_change_interval"
              v-validate="`required|between:0,13|decimal:0`"
              :error-messages="errors.collect('rent_change_interval')"
              class="pa-0 ma-0 mt-3"
              style="max-width: 63px"
              box
              attach
              medium
              condensed
              :readonly="viewOnly"
            />
            <span class="d-flex align-self-center ml-2 mt-2"> Months</span>
          </div>
        </hb-form>
        <hb-form
          v-if="isPropertyLevel"
          label="Notification"
          description="The notification date will be calculated based on the effective date. In the event of a failure, it will be retried on the day following the notification date."
          class="rent-label"
          multiple
          required
        >
          <div style="max-width: 500px">
            <span class="hb-text-light rent-settings-box-field-description">
              Notification Period
            </span>
            <div class="d-flex mb-n4 mt-n2">
              <hb-text-field
                v-model.number="form.notification_period"
                attach
                hide-details
                data-vv-as="Notification Period"
                data-vv-name="notification_period"
                v-validate="`required|between:1,999|decimal:0`"
                placeholder="0"
                :error-messages="errors.collect('notification_period')"
                class="py-3 mb-0"
                box
                small
                condensed
                :readonly="viewOnly"
              />
              <span class="d-flex align-self-center mb-3">Days</span>
            </div>
            <hb-select
              class="pt-4 pb-3"
              v-model="form.notification_document_id"
              style="max-width: 370px"
              attach
              hide-details
              :clearable="false"
              label="Document Name"
              :items="documents"
              no-data-text="No Documents Found"
              data-vv-as="Document Name"
              data-vv-name="notification_document_id"
              v-validate="`required`"
              :error-messages="errors.collect('notification_document_id')"
              item-text="name"
              item-value="id"
              :readonly="viewOnly"
            />

            <delivery-methods
              ref="deliveryMethods"
              :settings="form.delivery_methods"
              :is-property-level="isPropertyLevel"
              :property="property"
              :view-only="viewOnly"
              @updatedSettings="saveDeliveryMethods($event)"
              @showNotification="showNotification($event)"
              @hasError="hasError()"
            />
          </div>
        </hb-form>
        <RoundingForm v-model="form.round_to" attach :readOnly="viewOnly" />
        <hb-form
          v-if="isPropertyLevel"
          label="Rent Cap Default"
          description="When choosing your limit, pick a raise method and then designate the method value."
          multiple
          required
        >
          <div style="max-width: 700px">
            <div class="d-flex mb-n4 mt-n2">
              <span class="d-flex align-self-center mb-6">
                Rent cannot exceed a
                <span class="font-weight-medium px-1"> maximum </span> of
              </span>
              <hb-select
                v-model="form.rent_cap.type"
                attach
                ref="rent_cap_type"
                hide-details
                :clearable="false"
                placeholder="Choose Method"
                data-vv-as="Rent Cap Default"
                data-vv-name="rent_cap_default"
                v-validate="`required`"
                :items="rentCapMethods"
                item-text="text"
                item-value="id"
                :error-messages="errors.collect('rent_cap_default')"
                class="py-3 mb-0 px-2"
                :readonly="viewOnly"
              />
              <hb-text-field
                v-model="form.rent_cap.value"
                :disabled="!form.rent_cap.type"
                attach
                hide-details
                data-vv-as="Rent Cap Value"
                data-vv-name="rent_cap_value"
                v-validate="
                  `required_if:rent_cap_type,sell_rate_percent,set_rate_percent|between:1,999|decimal:0`
                "
                placeholder="0"
                maxlength="3"
                :error-messages="errors.collect('rent_cap_value')"
                class="py-2 mb-2"
                box
                small
                condensed
                :readonly="viewOnly"
              />
            </div>
          </div>
        </hb-form>
      </template>

      <hb-bottom-action-bar v-if="!viewOnly" @close="cancel" :topBorder="false">
        <template v-slot:right-actions>
          <hb-btn
            :disabled="!rentValuesChanged"
            :loading="rentApiLoading"
            @click="confirmData"
            >Save</hb-btn
          >
        </template>
      </hb-bottom-action-bar>
    </div>
    <template v-else-if="!isPropertyLevel">
      <div class="d-flex justify-center ma-0 pa-0 my-4">
        <v-progress-circular indeterminate color="primary" />
      </div>
    </template>

    <hb-modal :title="confirmationContent.title" v-model="dialog" confirmation>
      <template v-slot:content>
        <div class="py-4 px-6" v-html="confirmationContent.message"></div>
      </template>
      <template v-slot:right-actions>
        <hb-btn color="primary" @click="submitData" :loading="rentApiLoading"
          >Continue</hb-btn
        >
      </template>
    </hb-modal>
  </div>
</template>

<script type="text/babel">
import RoundingForm from "../../assets/RoundingForm.vue";
import DeliveryMethods from "./DeliveryMethods.vue";

import { cloneDeep, isEqual, startCase } from "lodash";

import api from "../../../assets/api.js";

export default {
  name: "rent-management-settings",
  mixins: [],
  props: {
    level: {
      type: String,
      default: "",
    },
    isPropertyLevel: {
      type: Boolean,
      default: false,
    },
    property: {
      type: String,
      default: "",
    },
    documents: {
      type: Array,
      default: () => [],
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rules: {
        wholeNumberRule: (val) => {
          return Number(val) % 1 === 0;
        },
      },
      loading: true,
      deliveryMethods: [],
      form: {
        active: "",
        approval_type: "",
        // default_space_group_profile_id: "",
        min_rent_change_interval: "",
        advance_rent_change_queue_months: "",
        notification_period: "",
        notification_document_id: "",
        delivery_methods: [],
        round_to: "",
        enable_automatic_rent_change: "",
        rent_cap: {
          type: "",
          value: "",
        },
      },
      method: {
        delivery_methods_id: "",
        active: true,
        subject: "",
        message: "[Tenant.FirstName] [Tenant.LastName]",
      },
      initilalNotificationData: {},
      dialog: false,
      rentCapMethods: [
        { id: "sell_rate_percent", text: "% of Sell Rate" },
        { id: "set_rate_percent", text: "% of Set Rate" },
      ],
      initialData: {},
      initalCloneData: null,
      spaceGroups: [],
      rentApiLoading: false,
    };
  },
  components: {
    RoundingForm,
    DeliveryMethods,
  },
  async created() {
    await this.setInitialData();
    if (!this.isPropertyLevel) await this.fetchCompanySettings();
  },
  computed: {
    rentEngine() {
      return startCase(this.form.rent_engine ?? "hummingbird");
    },
    /**
     * Checks if the form object is editted for the current level.
     *
     * @returns {Boolean} - true if current level in form object is editted, else false
     */
    rentValuesChanged() {
      const dirty = !isEqual(this.initialData, this.form);
      if (!dirty) this.$validator.errors.clear();
      return this.isPropertyLevel ? dirty && this.form.active : dirty;
    },

    isApprovalTypeManual() {
      return this.form?.approval_type === "manual";
    },

    enableAutomaticRent: {
      get() {
        return !!this.form.enable_automatic_rent_change;
      },
      set(value) {
        this.form.enable_automatic_rent_change = !!value;
      },
    },

    showAutomationSection() {
      return (
        this.isPropertyLevel &&
        this.rentEngine === "Hummingbird" &&
        this.form.automation_enabled_by_admin
      );
    },

    confirmationContent() {
      return {
        title: "Confirm Notification Period Change",
        message: `Changing the <strong>Notification period</strong> might cause issues with alerts or the generation of documents for Rent Changes that are planned. Are you sure you want to continue?`,
      };
    },
  },
  watch: {
    /**
     * Watches property to reset validation and fetch initial data.
     */
    property: {
      async handler(val) {
        this.$validator.reset();
        if (val) {
          await this.fetchData();
        }
      },
      immediate: true,
    },
  },

  methods: {
    showNotification(data) {
      this.$emit("setSnackBar", {
        type: data.type,
        message: data.message,
        errors: data.errors,
      });
    },

    generateNumbers(n) {
      return Array.from({ length: n }, (_, i) => i + 1);
    },

    saveDeliveryMethods(data) {
      this.form.delivery_methods = data;
    },

    getSpaceGroupDetails(spaceGroupId) {
      return this.spaceGroups.find((group) => group.id === spaceGroupId);
    },

    async setRentActive(status) {
      this.form.active = !!status;
    },

    async enableRentManagement(status) {
      this.form.active = !!status;
      if (this.isPropertyLevel) await this.updatePropertySettings();
      else await this.updateCompanySettings();
    },

    async confirmData() {
      this.$validator.errors.clear();
      if (this.isPropertyLevel) {
        let valid = await this.$refs["deliveryMethods"].validateFields();
        this.$validator.validateAll().then((sucess) => {
          if (sucess && valid) {
            if (
              this.initialData?.notification_period &&
              this.initialData?.notification_period !=
                this.form?.notification_period
            ) {
              this.dialog = true;
            } else {
              this.submitData();
            }
          }
        });
      } else {
        this.$validator.validateAll().then((sucess) => {
          if (sucess) {
            this.submitData();
          }
        });
      }
    },
    /**
     * Sets an initial state to compare and watch for changes
     * can be set for each level by passing type
     * if no type is passed, sets for both level
     * @param {String} type - can be property, corporate.
     */
    setInitialData() {
      this.initialData = cloneDeep(this.form);
      if (this.$refs?.deliveryMethods)
        this.$refs?.deliveryMethods?.saveDeliveryMethods();
      this.$emit("setEnableStatus", {
        status: !!this.form.active,
        type: "rent",
      });
    },

    /**
     * To reset to initial state
     *
     * Removes the changes done to the form object
     */
    cancel() {
      this.$nextTick(() => {
        this.form = cloneDeep(this.initialData);
        this.$validator.reset();
        this.notificationForm = cloneDeep(this.initilalNotificationData);
        this.$refs.deliveryMethods.resetDeliveryMethods();
        this.$emit("setEnableStatus", {
          status: !!this.form.active,
          type: "rent",
        });
      });
    },

    /**
     * Fetch initial data for the current level.
     */
    async fetchData() {
      this.loading = true;
      if (this.isPropertyLevel) {
        if (this.property) {
          this.fetchPropertySpaceGroup();
          await this.fetchPropertySettings();
        }
      }
      this.loading = false;
    },

    /**
     * Function for fetching space groups for the current property.
     *
     */
    async fetchPropertySpaceGroup() {
      try {
        let response = await api.get(
          this,
          api.PROPERTIES + this.property + "/space-groups"
        );
        this.spaceGroups = response.spaceGroups;
      } catch (err) {
        console.error("Space group fetch error", err);
      }
    },

    /**
     * Fetch Rent management configurations for property.
     */
    async fetchPropertySettings() {
      // this.form = {}
      try {
        let data = await api.get(
          this,
          api.getPropertyRentManagementUrl(this.property)
        );
        this.form = {
          ...this.form,
          ...data.property_rent_management_settings,
        };

        if (!this.form?.delivery_methods) {
          this.form.delivery_methods = [];
        } else if (!this.form?.delivery_methods.length)
          this.form.delivery_methods.push(this.method);
        if (!this.form?.rent_cap) {
          this.form.rent_cap = {
            type: "",
            value: "",
          };
        } // if rent cap is null or undefined set it with default empty values to 'type' and 'value'
      } catch (err) {
        this.$emit("setSnackBar", {
          type: "error",
          message: "Property configuration fetch error.",
        });
        console.error("Property configuration fetch error", err);
      }
      this.$emit("setLoading");
      this.setInitialData();
    },

    /**
     * Fetch Rent management configurations for company.
     */
    async fetchCompanySettings() {
      this.loading = true;
      try {
        let data = await api.get(this, api.COMPANY_RENT_MANAGEMENT);
        this.form = {
          ...this.form,
          ...data.company_rent_management_settings,
        };
      } catch (err) {
        this.$emit("setSnackBar", {
          type: "error",
          message: "Company configuration fetch error.",
        });
        console.error("Company configuration fetch error", err);
      }
      this.setInitialData();
      this.loading = false;
    },

    /**
     * Submit updated setting for the current level after validation checks.
     */
    async submitData() {
      if (this.isPropertyLevel) {
        if (this.property) await this.updatePropertySettings();
      } else await this.updateCompanySettings();
      this.dialog = false;
    },

    /**
     *  Submit Rent Managemnet settings for Company
     */
    async updateCompanySettings() {
      this.rentApiLoading = true;

      let updateData = { ...this.form };

      let data = {
        round_to: updateData?.round_to,
      };

      try {
        await api
          .put(this, api.COMPANY_RENT_MANAGEMENT, data, "", false)
          .then(() => {
            this.$emit("setSnackBar", {
              type: "success",
              message:
                "You have successfully updated the Rent Management Settings for this Company.",
            });
            this.setInitialData();
          })
          .catch((error) => {
            console.error(error, " <--- error");
            if (error.status === 403) {
              this.$emit("setSnackBar", {
                type: "error",
                message:
                  "You don't have enough permissions to update this configuration.",
              });
            } else {
              this.$emit("setSnackBar", {
                type: "error",
                message: "Corporate Rent Management update failed.",
              });
            }
          });
      } catch (err) {
        console.error("Failed to update", err);
      }
      this.rentApiLoading = false;
    },

    /**
     * Submits Rent management configurations for property.
     */
    async updatePropertySettings(switchableData = {}) {
      this.rentApiLoading = true;

      let data = {
        ...this.form,
      };

      if (!data.delivery_methods.length) {
        data.delivery_methods = null; // set delivery methods to null if non is selected
      }

      if (!data?.rent_cap?.type || !Object.keys(data.rent_cap).length) {
        data.rent_cap = null;
      } // set rent cap value to null if non is selected

      delete data.automation_enabled_by_admin; // remove admin automation key

      try {
        await api
          .put(
            this,
            api.getPropertyRentManagementUrl(this.property),
            data,
            "",
            false
          )
          .then(() => {
            this.$emit("setSnackBar", {
              type: "success",
              message:
                "You have successfully updated the Rent Management Settings for this Property.",
            });
            this.setInitialData();
          })
          .catch((error) => {
            console.error(error, " <--- error");
            if (error.status === 400) {
              // Validation on notification period on rent management settings to that of lease configuration
              this.$validator.errors.add({
                field: "notification_period",
                message: error.body.msg,
              });
            } else {
              this.$emit("setSnackBar", {
                type: "error",
                message: "Property Rent Management update failed.",
              });
            }
          });
      } catch (err) {
        console.error("Failed to update", err);
      }
      this.rentApiLoading = false;
    },
  },
};
</script>

<style lang="scss">
.hb-text-light.rent-settings-box-field-description {
  font-size: 11px;
  line-height: 13px;
}
</style>
