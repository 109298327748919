<template>
  <div>
    <div v-if="!loading">
      <template v-if="isPropertyLevel">
        <hb-form label="Engine">
          {{ rateEngine }}
        </hb-form>
        <!-- <hb-form label="Enable Rate Management">
          <hb-switch
            v-model="isEnabled"
            class="mt-2"
            label="Enable Rate Management for this Property"
          />
        </hb-form> -->
        <hb-form label="Space Grouping Profile" required>
          <hb-select
            v-model="form[level].space_group_profile"
            name="space_group"
            data-vv-as="space grouping profile"
            :error="emptySpaceProfile"
            placeholder="Select Space Grouping Profile"
            :items="spaceGroups"
            item-text="name"
            :item-value="
              (value) => {
                return { id: value.id, name: value.name };
              }
            "
            :clearable="false"
            class="pa-0 ma-0 mt-3 pb-1"
          >
          </hb-select>
        </hb-form>
      </template>

      <RoundingForm v-model="form[level].round_to" />

      <div v-if="isPropertyLevel">
        <hb-bottom-action-bar @close="cancel" :topBorder="false">
          <template v-slot:right-actions>
            <hb-btn
              :disabled="!rateValuesChanged"
              :loading="rateApiLoading"
              @click="confirmData"
              >Save</hb-btn
            >
          </template>
        </hb-bottom-action-bar>
      </div>
      <div v-else>
        <hb-bottom-action-bar @close="cancel" :topBorder="false">
          <template v-slot:right-actions>
            <hb-btn
              :disabled="!rateValuesChanged"
              :loading="rateApiLoading"
              @click="confirmData"
              >Save</hb-btn
            >
          </template>
        </hb-bottom-action-bar>
      </div>
    </div>
    <div
      v-else-if="!isPropertyLevel"
      class="d-flex justify-center ma-0 pa-0 my-4"
    >
      <v-progress-circular indeterminate color="primary" />
    </div>
    <hb-modal :title="confirmationContent.title" show-help-link v-model="dialog" confirmation>
      <template v-slot:content>
        <div class="py-4 px-6" v-html="confirmationContent.message"></div>
      </template>
      <template v-slot:right-actions>
        <hb-btn color="primary" @click="submitData" :loading="rateApiLoading"
          >Continue</hb-btn
        >
      </template>
    </hb-modal>
  </div>
</template>

<script type="text/babel">
import RoundingForm from "../../assets/RoundingForm.vue";

import { cloneDeep, isEqual, startCase } from "lodash";

import api from "../../../assets/api.js";

import { mapMutations } from "vuex";

export default {
  name: "rate-management-settings",
  mixins: [],
  props: {
    level: {
      type: String,
      default: "",
    },
    isPropertyLevel: {
      type: Boolean,
      default: false,
    },
    property: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      dialog: false,
      emptySpaceProfile: false,
      initialData: {},
      spaceGroups: [],
      rateApiLoading: false,
      form: {
        corporate: {
          active: false,
          round_to: null,
          default_space_group_profile_id: null,
        },
        property: {
          active: false,
          round_to: null,
          default_space_group_profile_id: null,
          space_group_profile: {
            id: null,
            name: null,
          },
        },
      },
    };
  },
  components: {
    RoundingForm,
  },
  async created() {
    this.setInitialData();
    if (!this.isPropertyLevel) await this.fetchCompanySettings();
  },
  computed: {
    /**
     * To show if rate management is disabled or not.
     * As the API returns the 'active' state, we need to take the negation
     */
    isEnabled: {
      get() {
        return !!this.form[this.level].active;
      },
      set(value) {
        this.form[this.level].active = !!value;
      },
    },
    rateEngine() {
      return startCase(this.form[this.level]?.rate_engine ?? "hummingbird");
    },

    /**
     * Checks if the form object is editted for the current level.
     *
     * @returns {Boolean} - true if current level in form object is editted, else false
     */
    rateValuesChanged() {
      return !isEqual(this.initialData[this.level], this.form[this.level]);
    },

    confirmationContent() {
      let initialData =
        this.initialData?.[this.level]?.space_group_profile?.name;
      return {
        title: "Confirm default space group profile change",
        message:
          "You are about to change the default space group profile" +
          `${
            initialData ? " from <strong>" + initialData + "</strong>" : ""
          } to <strong>${
            this.form?.[this.level]?.space_group_profile?.name
          }</strong>. This will reset all the Rate Plans to the company default plan. Do you want to proceed?`,
      };
    },
  },
  watch: {
    /**
     * Watches property to reset validation and fetch initial data.
     */
    property: {
      async handler(val) {
        this.$validator.reset();
        if (val) {
          await this.fetchData();
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations({
      updateCompanyRatePlanConf: "revManStore/SET_COMPANY_RATE_PLAN_CONF",
    }),

    async setRateActive(status) {
      this.form[this.level].active = !!status;
    },

    async enableRateManagement(status) {
      this.form[this.level].active = !!status;
      this.postPropertySettings();
    },

    confirmData() {
      if (
        !this.form[this.level]?.space_group_profile?.id &&
        this.isPropertyLevel
      ) {
        this.emptySpaceProfile = true;
        this.$emit("setSnackBar", {
          type: "error",
          message:
            "There are errors in your form, correct them before continuing.",
        });
      } else {
        this.emptySpaceProfile = false;
        if (
          this.initialData?.[this.level]?.space_group_profile?.name &&
          this.initialData?.[this.level]?.space_group_profile?.name !=
            this.form?.[this.level]?.space_group_profile?.name
        ) {
          this.dialog = true;
        } else {
          this.submitData();
        }
      }
    },
    /**
     * Sets an initial state to compare and watch for changes
     * can be set for each level by passing type
     * if no type is passed, sets for both level
     * @param {String} type - can be property, corporate.
     */
    setInitialData(type) {
      switch (type) {
        case "property":
          this.initialData.property = cloneDeep(this.form.property);
          this.$emit("setEnableStatus", {
            status: !!this.form.property.active,
            type: "rate",
          });
          break;
        case "corporate":
          this.initialData.corporate = cloneDeep(this.form.corporate);
          break;
        default:
          this.initialData = cloneDeep(this.form);
      }
    },

    /**
     * To reset to initial state
     *
     * Removes the changes done to the form object
     */
    cancel() {
      this.form = cloneDeep(this.initialData);
    },

    /**
     * Fetch initial data for the current level.
     */
    async fetchData() {
      this.loading = true;
      if (this.isPropertyLevel) {
        if (this.property) {
          await this.fetchPropertySpaceGroup();
          await this.fetchPropertySettings();
        }
      }
      this.loading = false;
    },

    /**
     * Function for fetching space groups for the current property.
     *
     */
    async fetchPropertySpaceGroup() {
      try {
        let response = await api.get(
          this,
          api.PROPERTIES + this.property + "/space-groups"
        );
        this.spaceGroups = response.spaceGroups;
      } catch (err) {
        console.error("Space group fetch error", err);
      }
    },

    /**
     * Fetch Rate management configurations for property.
     */
    async fetchPropertySettings() {
      try {
        this.form.property = await api.get(
          this,
          api.getPropertyRateManagementUrl(this.property)
        );
      } catch (err) {
        console.error("Property configuration fetch error", err);
      }
      this.$emit("setLoading");
      this.setInitialData("property");
    },

    /**
     * Fetch Rate management configurations for the company.
     */
    async fetchCompanySettings() {
      this.loading = true;
      try {
        this.form.corporate = await api.get(this, api.COMPANY_RATE_MANAGEMENT);
      } catch (err) {
        console.error("Company configuration fetch error", err);
      }
      this.setInitialData("corporate");
      this.loading = false;
    },
    /**
     * Submit updated setting for the current level after validation checks.
     */
    async submitData() {
      this.rateApiLoading = true;

      this.$validator.reset();
      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        this.rateApiLoading = false;
        this.$emit("setSnackBar", {
          type: "error",
          message:
            "There are errors in your form, correct them before continuing.",
          // this.errors.collect("space_group")
        });
        return;
      }
      if (this.isPropertyLevel) {
        if (this.property) await this.postPropertySettings();
      } else await this.postCompanySettings();
      this.dialog = false;
    },

    /**
     * Submits Rate management configurations for property.
     */
    async postPropertySettings() {
      try {
        let updated_body = {
          ...this.form.property,
          space_group_profile: {
            id: this.form?.property?.space_group_profile?.id ?? "",
          },
        };

        await api
          .put(
            this,
            api.getPropertyRateManagementUrl(this.property),
            updated_body,
            "",
            false
          )
          .then(() => {
            this.setInitialData("property");
            this.$emit("setSnackBar", {
              type: "success",
              message:
                "You have successfully updated the Rate Management Settings for this Property.",
            });
            this.$emit("setEnableStatus", {
              status: !!updated_body.active,
              type: "rate",
            });
          })
          .catch((error) => {
            this.$emit("setEnableStatus", {
              status: !updated_body.active,
              type: "rate",
            });
            if (error.status === 403) {
              // this.$emit("setSnackBar", {
              //   type: "error",
              //   message:
              //     "You don't have enough permissions to update this configuration.",
              // });
            } else {
              this.$emit("setSnackBar", {
                type: "error",
                message: "Property Rate Management update failed.",
              });
            }
          });
      } catch (err) {
        console.error("Property Rate Management update failed", err);
      }
      this.rateApiLoading = false;
    },
    /**
     * Submits Rate management configurations for the company.
     */
    async postCompanySettings() {
      try {
        let { round_to, default_rate_plan_id } = this.form.corporate ?? {};

        await api
          .put(
            this,
            api.COMPANY_RATE_MANAGEMENT,
            this.form.corporate,
            "",
            false
          )
          .then(() => {
            this.updateCompanyRatePlanConf({
              round_to,
              default_rate_plan_id,
            });

            this.setInitialData("corporate");
            this.$emit("setSnackBar", {
              type: "success",
              message:
                "You have successfully updated the Rate Management Settings",
            });
          })
          .catch((error) => {
            if (error.status === 403) {
              this.$emit("setSnackBar", {
                type: "error",
                message:
                  "You don't have enough permissions to update this configuration.",
              });
            } else {
              this.$emit("setSnackBar", {
                type: "error",
                message: "Corporate Rate Management update failed.",
              });
            }
          });
      } catch (err) {
        console.error("Corporate Rate Management update failed", err);
      }
      this.rateApiLoading = false;
    },
  },
};
</script>

<style lang="scss"></style>
